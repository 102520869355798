import styled from '@emotion/styled';
import { Tabs, Tab } from '@mui/material';

export const MyTabs = styled(Tabs)({
  background: '#F6F4F4',
  // outline: "2px solid #492D21",
  borderRadius: 30,
  width: '100%',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '@media(min-width: 500px)': {
    marginTop: 42,
  },
});

export const MyTab = styled(Tab)({
  flex: 1,
  maxWidth: '100%',
  borderRadius: 30,
  color: '#bfadad',
  fontSize: 18,
  textTransform: 'capitalize',
  lineHeight: '34px',
  padding: '7px 16px',
  '&.Mui-selected': {
    background: '#ddd',
    color: '#493c3c',
    borderRadius: 0,
  },
});
